{
  //Block right click
  document.addEventListener(
    "contextmenu",
    (e) => {
      e.preventDefault();
    },
    false
  );

  //Disable View Source
  document.addEventListener("keydown", (e) => {
    // USE THIS TO DISABLE CONTROL AND ALL FUNCTION KEYS
    // if (e.ctrlKey || (e.keyCode>=112 && e.keyCode<=123)) {
    // THIS WILL ONLY DISABLE CONTROL AND F12
    if (e.ctrlKey || e.keyCode == 123) {
      e.stopPropagation();
      e.preventDefault();
    }
  });

  //Countdown

  // {
  //   var todayDate = new Date();
  //   const yyyy = todayDate.getFullYear();
  //   let mm = todayDate.getMonth() + 1;
  //   let dd = todayDate.getDate();
  //   if (dd < 10) dd = "0" + dd;
  //   if (mm < 10) mm = "0" + mm;
  //   const formattedToday = yyyy + "-" + mm + "-" + dd + " 24:00:00";

  //   const closeInfoDate = dd + "." + mm + "." + yyyy;
  //   const closeInfoDayInt = todayDate.getDay();
  //   var closeInfoDay = todayDate.getDay();

  //   switch (closeInfoDayInt) {
  //     case 0:
  //       closeInfoDay = "niedzielę";
  //       break;
  //     case 1:
  //       closeInfoDay = "poniedziałek";
  //       break;
  //     case 2:
  //       closeInfoDay = "wtorek";
  //       break;
  //     case 3:
  //       closeInfoDay = "środę";
  //       break;
  //     case 4:
  //       closeInfoDay = "czwartek";
  //       break;
  //     case 5:
  //       closeInfoDay = "piątek";
  //       break;
  //     case 6:
  //       closeInfoDay = "sobotę";
  //       break;
  //     default:
  //       break;
  //   }

  //   var closeInfo =
  //     "Możliwość zamówienia w najniższej cenie kończy się w " +
  //     closeInfoDay +
  //     " (" +
  //     closeInfoDate +
  //     ") o 24:00";

  //   var closeInfoSpans = document.getElementsByClassName("start-course__label");
  //   for (var closeInfoSpan of closeInfoSpans) closeInfoSpan.innerHTML = closeInfo;

  //   var countDownDate = new Date(formattedToday).getTime();

  //   var intervalcountDownDate = setInterval(function () {
  //     var now = new Date().getTime();
  //     var distance = countDownDate - now;

  //     var days = 0;
  //     var hours = 0;
  //     var minutes = 0;
  //     var seconds = 0;

  //     if (distance > 0) {
  //       days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //       hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //       minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //     } else {
  //       clearInterval(intervalcountDownDate);
  //     }

  //     var daysSpan = document.getElementsByClassName("countdown-timer__days");
  //     var hoursSpan = document.getElementsByClassName("countdown-timer__hours");
  //     var minutesSpan = document.getElementsByClassName(
  //       "countdown-timer__minutes"
  //     );
  //     var secondsSpan = document.getElementsByClassName(
  //       "countdown-timer__seconds"
  //     );

  //     for (var daySpan of daysSpan)
  //       daySpan.innerHTML =
  //         days + "<small class='countdown-timer__label'>Dni</small>";

  //     for (var hourSpan of hoursSpan)
  //       hourSpan.innerHTML =
  //         hours + "<small class='countdown-timer__label'>Godzin</small>";

  //     for (var minuteSpan of minutesSpan)
  //       minuteSpan.innerHTML =
  //         minutes + "<small class='countdown-timer__label'>Minut</small>";

  //     for (var secondSpan of secondsSpan)
  //       secondSpan.innerHTML =
  //         seconds + "<small class='countdown-timer__label'>Sekund</small>";
  //   }, 1000);
  // }

  // // Counters
  function isInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      Math.round(rect.bottom) <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      Math.round(rect.right) <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const box = document.querySelector(".counter-block");

  var checkCounterIsInViewPort = function () {
    if (!isInViewport(box)) return;

    for (var counter of counters) {
      counter.innerText = "0";
      updateCounter(counter);
    }

    document.removeEventListener("scroll", checkCounterIsInViewPort);
  };

  document.addEventListener("scroll", checkCounterIsInViewPort, {
    passive: true,
  });

  var counters = document.getElementsByClassName("counter");

  function updateCounter(counterParam) {
    var targetValue = +counterParam.getAttribute("data-target");
    var actualValue = +counterParam.innerText;

    var increment = targetValue / 300;

    if (actualValue < targetValue) {
      counterParam.innerText = `${Math.ceil(actualValue + increment)}`;
      setTimeout(updateCounter, 1, counterParam);
    } else {
      counterParam.innerText = targetValue;
    }
  }

  // Collapisble

  var collapsibles = document.querySelectorAll(".collapsible");
  collapsibles.forEach((item) =>
    item.addEventListener("click", function () {
      this.classList.toggle("collapsible--expanded");
    })
  );

  var joinCartBtns = document.getElementsByClassName("join-cart");

  for (var joinCartBtn of joinCartBtns) {
    joinCartBtn.addEventListener("click", () => {
      window.location.href =
        "https://sklep.modestprogrammer.pl/koszyk-dodaj?productId=82&quantity=1";
      //addToCart(52, 1, 3);
    });
  }
}
